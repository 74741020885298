.home {
  &__banner {
    img {
      width: 100%;
      position: relative;
      // @include responsive-for(sm){
      //   width: 50%;
      // }
    }
  }
  &__bannerContent {
    position: absolute;
    top: 32%;
    overflow-x: hidden;
    @include responsive-for(md) {
      top: 45%;
    }
    @include responsive-for(sm) {
      top: 35%;
    }
    @include responsive-for(xs) {
      top: 30%;
    }
    @include responsive-for(xs) {
      top: 18%;
    }
    &--heading {
      font-family: 'Montserrat', 'sans-serif';
      font-style: normal;
      font-weight: 700;
      font-size: 36px;
      line-height: 54px;
      color: #ffffff;
      @include responsive-for(md) {
        font-size: 25px;
        line-height: 30px;
      }
      @include responsive-for(md) {
        font-size: 15px;
        line-height: 20px;
        width: 55%;
      }
    }
    &--paragraph {
      font-family: 'Roboto', 'sans-serif';
      font-style: normal;
      font-weight: 454;
      font-size: 18px;
      line-height: 29px;
      letter-spacing: 0.02em;
      color: #ffffff;
      margin-top: 24px;
      width: 95%;
      @include responsive-for(md) {
        margin-top: 10px;
        font-size: 15px;
        line-height: 18px;
      }
      @include responsive-for(sm) {
        display: none;
      }
    }
    a {
      font-family: 'Roboto', 'sans-serif';
      font-weight: 400;
      font-size: 18px;
      line-height: 26px;
      text-align: center;
      letter-spacing: 0.02em;
      background-color: #282828;
      color: #ffffff !important;
      margin-top: 44px;
      padding: 11px 34px;
      display: inline-block;
      @include responsive-for(md) {
        margin-top: 10px;
        font-size: 15px;
        padding: 8px 25px;
      }
      @include responsive-for(sm) {
        margin-top: 5px;
        font-size: 14px;
        padding: 5px 15px;
      }
    }
  }
  &__productheading {
    @include heading;
    margin-top: 60px;
    &::before {
      @include pseudoclass;
    }
  }
  &__products {
    margin-top: 44px;
    .abcd img {
      @include responsive-for(md) {
        width: 250px;
        height: 300px;
        object-fit: contain;
      }
    }
    &--id {
      font-family: 'Montserrat', 'sans-serif';
      font-weight: 700;
      font-size: 40px;
      line-height: 54px;
      letter-spacing: 0.02em;
      color: rgba(0, 0, 0, 0.15);
      margin-bottom: 0;
    }
    &--name {
      font-family: 'Montserrat', 'sans-serif';
      font-weight: 700;
      font-size: 26px;
      line-height: 54px;
      letter-spacing: 0.02em;
      color: #282828;
      margin: 0;
    }
    &--desc {
      font-family: 'Roboto', 'sans-serif';
      font-style: normal;
      font-weight: 400;
      font-size: 16px;
      line-height: 26px;
      letter-spacing: 0.02em;
      color: #282828;
    }
  }
  &__aboutus {
    margin-top: 60px;
    background-color: #fdf3ed;
    padding: 60px 0;
    video {
      width: 100%;
      height: 550px;
      @include responsive-for(md) {
        height: 280px;
      }
    }
    &--heading {
      @include heading;
      margin-bottom: 44px;
      &::before {
        @include pseudoclass;
      }
    }
  }
  &__testimonials {
    margin: 60px 0;
    .col-md-6 {
      margin: 0 auto;
      width: 97% !important;
    }
    @include heading;
    &::before {
      @include pseudoclass;
    }
    &--content {
      background-color: #f3f3f3;
      color: #000;
      padding: 30px;
      position: relative;
      margin-top: 40px;
      &-quote {
        position: absolute;
        color: #d0d0d0;
      }
      &-para {
        font-family: 'Roboto', 'sans-serif';
        font-style: normal;
        font-weight: 400;
        font-size: 15px;
        line-height: 23px;
        letter-spacing: 0.02em;
        text-align: center;
        margin-top: 40px;
      }
      &-name {
        font-family: 'Roboto', sans-serif;
        font-style: normal;
        font-weight: 500;
        font-size: 20px;
        line-height: 32px;
        text-align: center;
        letter-spacing: 0.02em;
        color: #282828;
        margin-bottom: 0;
      }
      &-post {
        font-family: 'Roboto';
        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        line-height: 26px;
        text-align: center;
        letter-spacing: 0.02em;
        color: #282828;
      }
    }
  }
  &__contactus {
    background-color: #fdf3ed;
    padding: 60px 0;
    iframe {
      height: 500px;
      width: 100%;
      @include responsive-for(md) {
        margin-top: 20px;
      }
    }
    button {
      background-color: #e36d17;
      border: none;
      border-radius: 0;
      width: 100%;
      padding: 16px;
      &:hover {
        background-color: #e36d17;
      }
    }
    &--heading {
      margin-bottom: 40px;
      @include heading;
      &::before {
        @include pseudoclass;
      }
    }
  }
}
.nthselector:nth-child(even) .abcd {
  order: 2;
}

.otherContactWrapper {
  margin: 5rem 0;

  h2 {
  }
  .BranchContact {
    height: auto;
    margin-bottom: 1rem;
    margin-top: 1rem;
    padding: 1rem;
    box-shadow: 1px 2px 10px #e36c1769;
    border-radius: $radius;
    &-location {
      font-size: 19px;
      color: color(primary, base);
    }
    &-name {
      font-size: 17px;
      margin-left: 2rem;
      margin-top: 0.5rem;
      font-weight: 400;
      color: #505050;
      &__post {
        color: #8d8d8d;
        font-weight: 300;
      }
    }
    &-Contact {
      font-size: 16px;
      margin-left: 2rem;
      margin-top: 0.5rem;
      font-weight: 400;
      color: #505050;
    }
  }
}

.ClassName {
  border: none;
  transition: transform 0.2s ease-in-out;
  svg {
    background: rgb(255 255 255 / 70%);
    path {
      color: black;
    }

    &:hover {
      background: rgb(255 255 255);
    }
  }

  &:hover {
    transform: scale(1.05);
  }
}
