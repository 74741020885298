.navbar-nav {
  .nav-link {
    font-family: "Roboto", "sans-serif" !important;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 19px;
    letter-spacing: 0.02em;
    color: #0d0d0d !important;
    margin-right: 40px;
  }
}
.navbar{
  position: sticky!important;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 999999;
  background: #fff;
  box-shadow: -1px -1px 10px 0px rgb(0 0 0 / 75%);
}
