/* 2.3 Font weight*/
$font-thin: 100;
$font-extra-light: 200;
$font-light: 300;
$font-regular: 400;
$font-medium: 500;
$font-semi-bold: 600;
$font-bold: 700;
$font-extra-bold: 800;


/* 2.1 Font Size*/
$text-xlg: 24px ;
$text-lg: 18px !default;
$text-md: 16px !default;
$text-sm: 14px !default;
$text-xs: 12px;

/* 3.0 Heading Size*/
$h1: 24px !default;
$h2: 20px !default;
// $h3: 25px !default;
// $h4: 20px;
// $h4: $large;
// $h5: $medium;
// $h6: $base;

$radius-buttons: 4px;
$radius: 6px;
$shadow: 1px 1px 14px  6px #bdbdbd10;
// $shadow-hover: 2px 2px 10px #93d3fd;

$border: 1px solid rgb(230, 230, 230);

// color variable map
$colors: (
  // non-nested values
  white:#FFFFFF, 
  text-dark: #333333,
  text-light: #4D4D4D,
  text-extra-light: #959595,
  // nested map inception
  primary:
    (
      base: #E36D17,
      hover: #183b77,
      tint: #F6F9FF,
      tint2: #3E6EC8,
      btn: #315CAC,
    ),
  secondary: (
    base: #f7941d,
    light: #f7c485,
    hover: #ffae4e,
    clicked: #e78a34,
  ),
  success: (
    base: #3CC13B,
    tint: #D7F3D7,
  ),
  danger: (
    base: #F03738,
    tint: #FCD7D7,
  ),
  warning: (
    base: #F3BB1C,
    tint: #FDF1D1,
  ),
  gray: (
    one: #B3B3B3,
    two: #CCCCCC,
    three: #E6E6E6,
    four: #F4F4F4,
    
  )
);

// retrieve color from $colors map ie. `color(base, primary)`
@function color($color-name, $color-variant: null) {
  // color variant is optional
  @if ($color-variant != null) {
    // map inception
    @return map-get(map-get($colors, $color-name), $color-variant);
  } @else {
    @return map-get($colors, $color-name);
  }
}




