.footer{
    background-color:#282828;
    padding: 35px 0 15px 0;
    color: #fff;
    p{
        margin-top: 28px;
        font-size: 18px;
        color: #E36D17;
        font-weight: 600;
    }
    &__desc{
        margin-top: 0px !important;
        color: #fff !important;
        font-weight: 300 !important;
        font: "Roboto",'sans-serif' !important;
    }
    ul{
        padding-left: 0;
        margin-top: 28px;
        li{
            padding-bottom: 12px;
            a{
                color: #fff !important;
                font-weight: 300 !important;

            }
        }
    }
   
    img{
        margin-bottom: 28px;
    }
    &__icon{
        font-size: 28px;
        margin-right: 15px;
    }
    &__secondheading{
        margin-left: 40px !important;
        @include responsive-for(md){
            margin-left: 0!important;
        }
    }
    &__listing{
         margin-left: 40px !important;
        @include responsive-for(md){
            margin-left: 0!important;
        }
    }
}